import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../../../components/Loading/Loading";
import { DestroyApi, GetApi, PostApi } from "../../../../../services/Api";
import "./styles.css";
import Mask from "../../../../../services/Mask";
import ConsumesCreate from "./components/ConsumesCreate";
import ConsumesView from "./components/ConsumesView";
import MonthSelector from "./components/MonthSelector";
import ConsumesResume from "./components/ConsumesResume";

const Consumes = ({ hidden = false }) => {
  let { worker_id, companie_id } = useParams();
  let containerRef = useRef();
  let [loading, setLoading] = useState(true);
  let [consumes, setConsumes] = useState([]);
  let [selectedDate, setSelectedDate] = useState(new Date());
  let getConsumes = async () => {
    let { status, body } = await GetApi(`/workers/${worker_id}/consumes`, {
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    });
    // if (loading) return;
    if (status === 200) {
      setConsumes(body.consumes);
    }
    setLoading(false);
  };
  let addConsumes = (newConsume) => {
    setConsumes([...consumes, newConsume]);
  };
  useEffect(() => {
    getConsumes();
  }, [selectedDate]);
  const handleLoad = () => {
    if (containerRef.current) {
      console.log(containerRef.current);
      containerRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  if (hidden) return null;
  return (
    <section id="ConsumesPage">
      <section id="Consumes">
        <section className="container" ref={containerRef} >
          <MonthSelector
            currentDate={selectedDate}
            setDate={(date) => {
              setSelectedDate(date);
            }}
          />
          <div className="header">
            <span>Nome:</span>
            <span>Valor Unitário:</span>
            <span>Quantidade:</span>
            <span>Valor total:</span>
            <span>Data:</span>
          </div>
          <Loading isOpen={loading} />
          {consumes.map((consume) => (
            <ConsumesView
              key={consume.id}
              consume={consume}
              companie_id={companie_id}
              worker_id={worker_id}
              update={getConsumes}
            />
          ))}
          <ConsumesResume consumes={consumes} />
        </section>
        <button onClick={handleLoad}>scrool</button>
        <ConsumesCreate
          companie_id={companie_id}
          worker_id={worker_id}
          update={getConsumes}
          add={addConsumes}
        />
      </section>
    </section>
  );
};

export default Consumes;
