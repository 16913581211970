import React, { useState } from "react";
import Input from "../../components/Input/Input";
import Mask from "../../services/Mask";
import Loading from "../../components/Loading/Loading";
import { PostApi } from "../../services/Api";

const CreateCompanie = ({ update = () => {}, close = () => {} }) => {
  let [companie, setCompanie] = useState({});
  let [loading, setLoading] = useState(false);
  let [message, setMessage] = useState("");
  const handleChange = ({ target }) => {
    setCompanie({ ...companie, [target.id]: target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);
    setMessage("");
    let { status, body } = await PostApi("/companies", companie);
    setLoading(false);
    if (status === 201) {
      await update();
      //  close()
    } else if (status === 409) {
      setMessage(body.message || "erro");
    } else {
      setMessage("Erro desconhecido");
    }
  };
  return (
    <div>
      <h1>Criar companie</h1>
      <h2 className="error">{message}</h2>
      <Loading isOpen={loading} />
      <form onSubmit={handleSubmit}>
        <Input
          id="name"
          label="Nome"
          value={companie.name}
          onChange={handleChange}
          required
        />
        <Input
          id="cnpj"
          label="cpnj"
          value={Mask.cnpj(companie.cnpj)}
          maxLength="18"
          minLength="18"
          onChange={handleChange}
          required="on"
        />
        <button type="submit">Criar</button>
      </form>
    </div>
  );
};

export default CreateCompanie;
