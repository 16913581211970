import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import WorkerConsumes from "./components/WorkerConsumes";
import { GetApi } from "../../services/Api";
import "./styles.css";
import Mask from "../../services/Mask";

const monthList = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const ConsumesPage = () => {
  let [workers, setWorkers] = useState([]);
  let [selectedMonth, setSelectedMonth] = useState(new Date());
  let [consumesOptions, setConsumeOptions] = useState([]);
  let getWorkers = async () => {
    let { status, body } = await GetApi(`/workers`, {
      year: selectedMonth.getFullYear(),
      month: selectedMonth.getMonth(),
    });

    if (status === 200) {
      setWorkers(body.workers);
    }
  };
  const handlePreviusMonth = () => {
    selectedMonth.setMonth(selectedMonth.getMonth() - 1);
    setSelectedMonth(new Date(selectedMonth));
  };
  const handleNextMonth = () => {
    selectedMonth.setMonth(selectedMonth.getMonth() + 1);
    setSelectedMonth(new Date(selectedMonth));
  };
  let handleReports = async () => {
    let { status, body } = await GetApi(`/consumes/reports`, {
      year: selectedMonth.getFullYear(),
      month: selectedMonth.getMonth() + 1,
    });

    if (status === 200) {
      let relatorio = body.workers.map((worker) => {
        return `${worker.name}: ${Mask.moeda(worker.consumes)}`;
      });

      if (!navigator.clipboard) {
        console.log("Imposivel copiar");
      } else {
        navigator.clipboard.writeText(relatorio.join("\n"));
        console.log("copiado");
      }
    }
  };
  
const getConsumeOptions = async () => {
    let { status, body } = await GetApi(`consumeoptions`);
    if (status === 200) {
      setConsumeOptions(body.options);
    }
  };

  
useEffect(() => {
    getConsumeOptions();
    getWorkers(selectedMonth);
  }, []);
  return (
    <>
      <Header />
      <aside>
        <button onClick={handleReports}>Relartorio</button>
      </aside>
      <main id="consumesPage">
        <section id="monthSelector">
          <div onClick={handlePreviusMonth}>{"<"}</div>
          <div>{monthList[selectedMonth.getMonth()]}</div>
          <div onClick={handleNextMonth}>{">"}</div>
        </section>
        <section id="ConsumesTable">
          <header>
            <div>Nome</div>
            <div>Agua</div>
            <div>Refrigerante</div>
            <div>Sobremesas</div>
            <div>outros</div>
            <div>Valor</div>
          </header>
          {workers.map((worker) => {
           
            return (
              <WorkerConsumes
                selectedMonth={selectedMonth}
                worker={worker}
                consumeOptions={consumesOptions}
              />
            );
          })}
        </section>
      </main>
    </>
  );
};

export default ConsumesPage;
