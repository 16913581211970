import React, { useEffect, useState } from 'react'
import { GetApi, PostApi } from '../../../../../../services/Api';

const ConsumesCreate = ({
    companie_id,
    worker_id,
    update = () => {},
    add = () => {},
  }) => {
    let [consumeOptions, setConsumeOptions] = useState([]);
    let [loading, setLoading] = useState(false);
    let [currentCunsume, setCurrentConsume] = useState({
      name: "",
      value: "",
      amount: 1,
    });
  
    let handleSubmit = async (event) => {
      event.preventDefault();
      if (loading) return;
      setLoading(true);
      let { status, body } = await PostApi(
        `/workers/${worker_id}/consumes`,
        currentCunsume
      );
      setLoading(false);
      if (status === 201) {
        setCurrentConsume({ name: "", value: "", amount: 1 });
        add(body.consume);
        // update();
      }
    };
  
    const getOptions = async () => {
      let { status, body } = await GetApi(`consumeOptions`);
      if (status === 200) {
        setConsumeOptions(body.options);
      }
    };
  
    let handleChange = ({ target }) => {
      setCurrentConsume({ ...currentCunsume, [target.id]: target.value });
  
      if (target.id === "name") {
        let consumeOptionsFinded = consumeOptions.find(
          (element) => element.name === target.value
        );
  
        if (consumeOptionsFinded) {
          setCurrentConsume({
            ...currentCunsume,
            value: consumeOptionsFinded.value,
            [target.id]: target.value,
          });
        }
      }
    };
  
    useEffect(() => {
      getOptions();
    }, []);
  
    return (
      <>
        <form onSubmit={handleSubmit}>
          <span>
            <label htmlFor="name">Item:</label>
            <input
              onChange={handleChange}
              id="name"
              type="text"
              list="consumeOptions"
              value={currentCunsume.name}
              required
              autoComplete="off"
            />
            <datalist id="consumeOptions">
              {consumeOptions.map((option) => {
                return <option key={option.id} value={option.name}></option>;
              })}
            </datalist>
          </span>
          <span>
            <label htmlFor="value">Valor:</label>
            <input
              id="value"
              onChange={handleChange}
              type="number"
              value={currentCunsume.value}
              min="0.01"
              step="0.01"
              pattern="\d"
              required
            />
          </span>
          <span>
            <label htmlFor="amount">Quantidade: </label>
            <input
              id="amount"
              onChange={handleChange}
              type="number"
              value={currentCunsume.amount}
              min="0.1"
              step="0.1"
  
              required
            />
          </span>
          <span>
            <button type="submit">Criar</button>
          </span>
        </form>
      </>
    );
  };
  

export default ConsumesCreate