import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import ErrorPage from "./pages/ErrorPage";
import DashBoardPage from "./pages/DashBoardPage";
import ProfilePage from "./pages/ProfilePage";
import CompaniesList from "./pages/companies/CompaniesList";
import CompaniePage from "./pages/companies/CompaniePage";
import WorkersList from "./pages/workers/WorkersList";
import ConsumesPage from "./pages/Consumes/ConsumesPage";
import AdmissionSheatPage from "./pages/AdmissionSheat/AdmissionSheatPage";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<MainPage />} />
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/register" element={<RegisterPage />} />
      <Route exact path="/error" element={<RegisterPage />} />
      <Route exact path="/dashboard" element={<DashBoardPage />} />
      <Route exact path="/companies" element={<CompaniesList />} />
      <Route exact path="/consumes" element={<ConsumesPage />} />
      <Route exact path="/companies/:companie_id" element={<CompaniePage />} />
      <Route exact path="workers" element={<WorkersList />} />
      <Route exact path="/AdmissionSheat" element={<AdmissionSheatPage />} />
      <Route exact path="workers/:worker_id" element={<WorkersList />} />
      <Route exact path="/profile" element={<ProfilePage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
