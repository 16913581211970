import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useParams } from "react-router-dom";
import { GetApi } from "../../services/Api";
import Loading from "../../components/Loading/Loading";

const CompaniePage = () => {
  let { companie_id } = useParams();
  let [companie, setCompanie] = useState(false);
  let getCompanie = async () => {
    let { status, body } = await GetApi(`/companies/${companie_id}`);

    if (status === 200) {
      setCompanie(body.companie);
    }
  };
  useEffect(() => {
    getCompanie();
  }, []);
  return (
    <>
      <Header />
      <main id="Companie">
        <div>CompaniePage</div>
        <Loading isOpen={!companie} />
        <div>Companie id: {companie_id}</div>

        {companie && <h1>Name: {companie.name}</h1>}
      </main>
    </>
  );
};

export default CompaniePage;
