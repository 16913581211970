import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetApi } from "../../services/Api";
import Modal from "../../components/Modal/Modal";
import Header from "../../components/Header/Header";
import CreateWorker from "./create/CreateWorker";
import WorkerPage from "./Worker/WorkerPage";
import Mask from "../../services/Mask";

const WorkersList = () => {
  let { worker_id } = useParams();
  let [workers, setWorkers] = useState([]);
  let [modal, setModal] = useState(false);
  let Navigate = useNavigate();
  let getWorkers = async () => {
    let { status, body } = await GetApi(`/workers`);
    if (status === 200) {
      setWorkers(body.workers);
    }
  };
  let handleStatus = async () => {
    let { status, body } = await GetApi(`/consumes/reports`);

    if (status === 200) {
      let relatorio = body.workers.map((worker) => {
        return `${worker.name}: ${Mask.moeda(worker.consumes)}`;
      });

      if (!navigator.clipboard) {
        console.log("Imposivel copiar");
      } else {
        navigator.clipboard.writeText(relatorio.join("\n"));
        console.log("copiado");
      }
    }
  };
  useEffect(() => {
    getWorkers();
  }, []);

  return (
    <>
      <Header />
      <main id="workers">
        <Modal isOpen={modal} close={() => setModal(false)}>
          <CreateWorker update={getWorkers} />
        </Modal>
        <Modal isOpen={!!worker_id} close={() => Navigate(`/workers`)}>
          <WorkerPage
            update={getWorkers}
            worker={workers.find((worker) => worker.id === parseInt(worker_id))}
          />
        </Modal>
        <button onClick={() => setModal(true)}>Criar</button>
        <button onClick={handleStatus}>relatorio</button>
        <section id="workersList">
          {workers.map((worker) => {
            if (worker.status !== 0) return null;
            return (
              <div
                className="card"
                key={worker.id}
                onClick={() => Navigate(`${worker.id}`)}
              >
                {worker.name}
              </div>
            );
          })}
        </section>
        <div>
          <h2>Outros</h2>
          <section id="workersList" className="">
            {workers.map((worker) => {
              if (worker.status === 0) return null;
              return (
                <div
                  className="card"
                  key={worker.id}
                  onClick={() => Navigate(`${worker.id}`)}
                >
                  {worker.name}
                </div>
              );
            })}
          </section>
        </div>
      </main>
    </>
  );
};
export default WorkersList;
