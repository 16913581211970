import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header/Header";
import { GetApi } from "../../services/Api";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import Modal from "../../components/Modal/Modal";
import CreateCompanie from "./CreateCompanie";
import Mask from "../../services/Mask";

const CompaniesList = () => {
  let [companies, setCompanies] = useState([]);
  let [loading, setLoading] = useState([]);
  let [modalCreate, setModalCreate] = useState(false);
  let [selectedCompanie, setSelectedCompanie] = useLocalStorage(
    "selectedCompanie",
    0
  );
  let Navigate = useNavigate();

  let getCompanies = async () => {
    setLoading(true);
    let { status, body } = await GetApi("companies");
    if (status === 200) {
      setCompanies(body.companies);
    }
    setLoading(false);
  };

  let handleSelectCompanie = async (id) => {
    setSelectedCompanie(id);
    let { status, message } = await GetApi(`companies/set/${id}`);
    if (status === 202) {
      Navigate(`/workers`);
    }
  };

  useMemo(() => {
    getCompanies();
  }, []);

  return (
    <>
      <Header />
      <main id="Companies">
        <Modal isOpen={modalCreate} close={() => setModalCreate(false)}>
          <CreateCompanie
            close={() => setModalCreate(false)}
            update={getCompanies}
          />
        </Modal>
        {/* <button onClick={getCompanies}>refresh</button> */}
        <button onClick={() => setModalCreate(true)}>create</button>
        <div>CompaniesList</div>
        <Loading isOpen={loading} />
        <section className="companiesList">
          {companies.map((companie) => {
            return (
              <div
                key={companie.id}
                onClick={() => handleSelectCompanie(companie.id)}
                id={companie.id}
                className="card"
              >
                <h1>{companie.name}</h1>
                <h3>Cnpj: {Mask.cnpj(companie.cnpj)}</h3>
              </div>
            );
          })}
        </section>
      </main>
    </>
  );
};

export default CompaniesList;
