import React, { useState } from "react";
import Mask from "../../../services/Mask";
import { useParams } from "react-router-dom";
import Properties from "../../../components/Properties/Properties";
import Input from "../../../components/Input/Input";
import { PostApi } from "../../../services/Api";

const CreateWorker = ({ update = () => {} }) => {
  let { companie_id } = useParams();
  let [worker, setWorker] = useState({ properties: [] });
  let [loading, setLoading] = useState(false);
  let [message, setMessage] = useState("");

  let handleChange = ({ target }) => {
    if (target.id === "cpf") target.value = Mask.cpf(target.value);
    if (target.id === "phone") target.value = Mask.phone(target.value);
    setWorker({ ...worker, [target.id]: target.value });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);
    let { status, body, ...response } = await PostApi(`/workers`, worker);
    if (status === 201) {
      update();
      setWorker({ properties: [] });
    } else {
      setMessage(body.message);
    }
    console.log(response);
    setLoading(false);
  };

  let addProperties = (key, value) => {
    setWorker({
      ...worker,
      properties: [...worker.properties, { key, value }],
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit} autocomplete="off">
        <div>{message}</div>

        <Input
          label="Nome"
          type="text"
          value={worker.name}
          id="name"
          onChange={handleChange}
          autocomplete="off"
          required
        />

        <Input
          label="cpf"
          maxLength="14"
          minLength="14"
          type="text"
          value={worker.cpf}
          id="cpf"
          onChange={handleChange}
          autocomplete="off"
          required
        />
        <Input
          label="Aniversario"
          type="date"
          value={worker.birthday}
          id="birthday"
          onChange={handleChange}
          autocomplete="off"
        />

        <Input
          label="Telefone"
          type="text"
          value={worker.phone}
          id="phone"
          onChange={handleChange}
          autocomplete="off"
          maxLength="17"
        />

        <Input
          label="Endereço"
          type="text"
          value={worker.address}
          id="address"
          onChange={handleChange}
          autocomplete="off"
        />
        <Input
          label="Setor"
          type="text"
          value={worker.sector}
          id="sector"
          onChange={handleChange}
          autocomplete="off"
        />
        <Input
          label="Função"
          type="text"
          value={worker.task}
          id="task"
          onChange={handleChange}
          autocomplete="off"
        />

        <div>
          <select name="status" id="status" value={0}>
            <option value={0}>Ativo</option>
            <option value={1}>Demitido</option>
            <option value={2}>Auxilio doença</option>
            <option value={3}>Freelancer</option>
          </select>
        </div>
        <button type="submit">Criar</button>
      </form>
     
    </>
  );
};

export default CreateWorker;
