import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <main id="Error">
      <h1>404 Error</h1>
      <h2>Pagina não encontrada</h2>
      <Link to="/">Main Page</Link>
    </main>
  );
};

export default ErrorPage;
