import React, { useEffect, useState } from 'react'
import { DestroyApi } from '../../../../../../services/Api';
import Mask from '../../../../../../services/Mask';


const ConsumesView = ({ worker_id, update = () => {}, consume }) => {
  let [optionsViwe, setOptionsViwe] = useState(false);
  let [deleted, setDeleted] = useState(false);
  let handleDelete = async () => {
    let { status } = await DestroyApi(
      `/workers/${worker_id}/consumes/${consume.id}`
    );
    if (status === 200) {
      setDeleted(true);
      update();
    }
  };

  if (deleted) return null;
  return (
    <div
      className="consume"
      key={consume.id}
      value={consume.id}
    >
      <div className="infos" onClick={() => setOptionsViwe(!optionsViwe)}>
        <span>{consume.name}</span>
        <span>{Mask.moeda(consume.value)}</span>
        <span>{consume.amount}</span>
        <span>{Mask.moeda(consume.value * consume.amount)}</span>
        <span>{Mask.dataPop(consume.created_at)}</span>
      </div>
      {optionsViwe && (
        <div className="options">
          <button onClick={handleDelete}>delete</button>
        </div>
      )}
    </div>
  );
};

export default ConsumesView;
