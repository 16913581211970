import React, { useEffect, useState } from "react";
import { GetApi } from "../../../services/Api";
import Mask from "../../../services/Mask";
import AddButton from "./AddButton";
import RemoveButton from "./RemoveButton";
const SumAmountReduce = (current, next) => {
  return current + next.amount;
};
const totalReduce = (current, next) => {
  return current + next.value * next.amount;
};
const WorkerConsumes = ({ worker, selectedMonth, consumeOptions }) => {
  let [consumes, setConsumes] = useState([]);
  let getConsumes = async () => {
    let { status, body } = await GetApi(`/workers/${worker.id}/consumes`, {
      month: selectedMonth.getMonth() + 1,
      year: selectedMonth.getFullYear(),
    });
    if (status === 200) {
      setConsumes(body.consumes);
    }
  };

  const addConsume = (consume) => {};
  const removeConsume = (consume) => {};
  useEffect(() => {
    getConsumes();
  }, [selectedMonth]);

  if (worker.status !== 0 && !consumes.length) return null;
  return (
    <div className="workerConsume">
      <div>{worker.name || ""}</div>
      <div>
      <RemoveButton
          name={"agua"}
          consumes={consumes}
          worker={worker}
          update={getConsumes}
        />
        {consumes
          .filter((consume) => consume.name === "agua")
          .reduce(SumAmountReduce, 0)}
        <AddButton
          worker={worker}
          consumeOptions={consumeOptions}
          name="agua"
          update={getConsumes}
        />
      </div>
      <div>
        <RemoveButton
          name={"Refrigerante"}
          consumes={consumes}
          worker={worker}
          update={getConsumes}
        />
        {consumes
          .filter((consume) => consume.name === "Refrigerante")
          .reduce(SumAmountReduce, 0)}
        <AddButton
          worker={worker}
          consumeOptions={consumeOptions}
          name="Refrigerante"
          update={getConsumes}
        />
      </div>
      <div>
        <RemoveButton
          name={"Sobremesas"}
          consumes={consumes}
          worker={worker}
          update={getConsumes}
        />
        {consumes
          .filter((consume) => consume.name === "Sobremesa")
          .reduce(SumAmountReduce, 0)}
        <AddButton
          worker={worker}
          consumeOptions={consumeOptions}
          name="Sobremesa"
          update={getConsumes}
        />
      </div>
      <div>outros</div>
      <div>{Mask.moeda(consumes.reduce(totalReduce, 0))}</div>
    </div>
  );
};

export default WorkerConsumes;
