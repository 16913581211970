import { Link } from "react-router-dom";
import "./header.css";
import useLocalStorage from "../../hooks/useLocalStorage";
const Header = () => {
  let [selectedCompanie] = useLocalStorage("selectedCompanie", 0);
  return (
    <header id="Header">
      <section>
        <h1>Tua terra</h1>
      </section>
      <nav>
        <Link to="/companies">
          <button>companies</button>
        </Link>
        {selectedCompanie !== 0 && (
          <Link to={`/workers`}>
            <button>workers</button>
          </Link>
        )}
        {selectedCompanie !== 0 && (
          <Link to={`/admissionsheat`}>
            <button>ficha</button>
          </Link>
        )}
        {selectedCompanie !== 0 && (
          <Link to={`/consumes`}>
            <button>consumes</button>
          </Link>
        )}
        <Link to="/profile">
          <button>Perfil</button>
        </Link>
      </nav>
    </header>
  );
};
export default Header;
