import React from "react";
import { PostApi } from "../../../services/Api";

const AddButton = ({ worker, name, consumeOptions, update }) => {
  const handleClick = async () => {
    let { status, body } = await PostApi(`workers/${worker.id}/consumes`, {
      name,
      value: consumeOptions.find((option) => option.name === name).value,
      amount: 1,
    });
    if(status===201){
        update()
    }
  };
  return (
    <div className="consumebutton" onClick={handleClick}>
      +
    </div>
  );
};

export default AddButton;
