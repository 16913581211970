class Mask {
  phone(string = "") {
    if (!string) string = "";
    string = string.replace(/\D/g, "");
    string = string.replace(/^(\d\d)(\d)/g, "($1) $2");
    string = string.replace(/(\d)(\d{2})/, " $1 $2");
    string = string.replace(/(\d{4})(\d)/, "$1-$2");
    return string;
  }
  cnpj(string = "") {
    if (!string) string = "";
    string = string.replace(/\D/g, "");
    string = string.replace(/(\d{2})(\d)/, "$1.$2");
    string = string.replace(/(\d{3})(\d)/, "$1.$2");
    string = string.replace(/(\d{3})(\d)/, "$1/$2");
    string = string.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return string;
  }
  cpf(string = "") {
    if (!string) string = "";
    string = string.replace(/\D/g, "");
    string = string.replace(/(\d{3})(\d)/, "$1.$2");
    string = string.replace(/(\d{3})(\d)/, "$1.$2");
    string = string.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return string;
  }
  data(data = new Date()) {
    let date = new Date(data);

    return `${this.format(date.getDate())}/${this.format(
      date.getMonth() + 1
    )}/${date.getFullYear()} ${this.format(date.getHours())}:${this.format(
      date.getMinutes()
    )}`;
  }

  dataPop(data) {
    
    if(!data)return null
    data = new Date(data);
    return data.toLocaleDateString()
   
  }
  handleData(data = new Date()) {
    let date = new Date(data);
    return {
      dia: date.getDate(),
      mes: date.getMonth(),
      ano: date.getFullYear(),
      date: this.data(data),
      pop: this.dataPop(data),
    };
  }

  moeda(number) {
    return number.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  format(number) {
    if (number >= 10) {
      return `${number}`;
    }
    return `0${number}`;
  }
}

export default new Mask();
