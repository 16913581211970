import React, { useEffect } from "react";
import "./styles.css";

const Modal = ({
  children,
  isOpen = false,
  close = () => {},
  id = "Modal",
}) => {
  useEffect(() => {
    if (!window) return;

    let keyUpListener = (e) => {
      if (e.keyCode === 27) close(false);
    };
    window.addEventListener("keyup", keyUpListener);
    return () => window.removeEventListener("keyup", keyUpListener);
  });

  const handleClose = ({ target }) => {
    if (target.id === id || target.id === "close") close(false);
  };
  if (!isOpen) return null;

  return (
    <span id={id} className="Modal" onClick={handleClose}>
      <main onClick={() => {}}>
        <span
          id="close"
          className="material-symbols-outlined"
          onClick={handleClose}
        >
          close
        </span>
        {children}
      </main>
    </span>
  );
};

export default Modal;
