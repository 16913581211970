import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Input from "../../components/Input/Input";
import "./styles.css";
import Mask from "../../services/Mask";
import Dependents from "./components/Dependents";
const AdmissionSheatPage = () => {
  let [data, setData] = useState({
    companieName: "Tua Terra Restaurante",
    companieCNPJ: "30882662000101",
  });

  const handleChange = ({ target }) => {
    let { id, value } = target;

    setData({ ...data, [id]: value });
  };
  return (
    <>
      <Header />
      <main id="AdmissionSheat">
        <div className="page">
          <fieldset>
            <legend>Empresa:</legend>
            <Input
              label="Nome"
              id="companiName"
              onChange={handleChange}
              value={data["companieName"]}
            />
            <Input
              label="CNPJ"
              value={Mask.cnpj(data["companieCNPJ"])}
              id="companieCNPJ"
              maxLength="18"
              onChange={handleChange}
            />
          </fieldset>
          <fieldset>
            {/* Funcionario */}
            <legend>Funcionario:</legend>
            <Input
              label="Nome"
              id="namef"
              onChange={handleChange}
              value={data["namef"] || ""}
            />
            <Input
              label="Gênero/Sexo"
              id="gender"
              onChange={handleChange}
              value={data["gender"] || ""}
              list="genders"
            />
            <datalist name="genders" id="genders">
              <option value="Feminino">Feminino</option>
              <option value="Masculino">Masculino</option>
            </datalist>
            <Input
              label="Raça/Cor"
              id="color"
              onChange={handleChange}
              value={data["color"] || ""}
            />
            <Input
              label="Nome Do Pai"
              id="father"
              onChange={handleChange}
              value={data["father"] || ""}
            />
            <Input
              label="Nome Da Mãe"
              id="mother"
              onChange={handleChange}
              value={data["mother"] || ""}
            />
            <Input
              label="Data de Nascimento"
              type="date"
              id="birthdate"
              onChange={handleChange}
              value={data["birthdate"] || ""}
            />
            <Input
              label="Natural de"
              id="naturalness"
              onChange={handleChange}
              value={data["naturalness"] || ""}
            />
            <Input
              label="Escolaridade"
              id="Education"
              onChange={handleChange}
              value={data["Education"] || ""}
            />
            <Input
              label="Estado Civil"
              id="marital"
              onChange={handleChange}
              value={data["marital"] || ""}
            />
            <Input
              label="Telefone"
              id="phone"
              maxLength="17"
              onChange={handleChange}
              value={Mask.phone(data["phone"]) || ""}
            />
          </fieldset>
          <fieldset>
            {/* Endereço */}
            <legend>Endereço:</legend>
            <Input
              label="Rua"
              id="adress"
              onChange={handleChange}
              value={data["adress"] || ""}
            />
            <Input
              label="Número"
              id="adressNumber"
              onChange={handleChange}
              value={data["adressNumber"] || ""}
              type="number"
            />
            <Input
              label="Bairro"
              id="bairro"
              onChange={handleChange}
              value={data["bairro"] || ""}
            />
            <Input
              label="Complemento"
              id="adressComplemento"
              onChange={handleChange}
              value={data["adressComplemento"] || ""}
            />
            <Input
              label="Cidade"
              id="city"
              onChange={handleChange}
              value={data["city"] || ""}
            />
            <Input
              label="CEP"
              id="zipCode"
              onChange={handleChange}
              value={data["zipCode"] || ""}
            />
          </fieldset>
          <fieldset>
            {" "}
            {/* Documentos */}
            <legend>Documentos:</legend>
            <Input
              label="PIS"
              id="pis"
              onChange={handleChange}
              value={data["pis"] || ""}
            />
            <Input
              label="CPF"
              id="cpff"
              onChange={handleChange}
              value={Mask.cpf(data["cpff"]) || ""}
            />
            <Input
              label="RG"
              id="rg"
              onChange={handleChange}
              value={data["rg"] || ""}
            />
            <Input
              label="Órgão Emissor"
              id="or"
              onChange={handleChange}
              value={data["or"] || ""}
            />
            <Input
              label="UF"
              id="uf"
              onChange={handleChange}
              value={data["uf"] || ""}
            />
            <Input
              label="Data da Emissão"
              type="date"
              id="emicion"
              onChange={handleChange}
              value={data["emicion"] || ""}
            />
          </fieldset>
          <fieldset>
            <legend>Titulo Eleitoral:</legend>
            <Input
              label="Número do Titulo"
              id="titleNumber"
              onChange={handleChange}
              value={data["titleNumber"] || ""}
            />
            <Input
              label="Zona"
              id="titleZone"
              onChange={handleChange}
              value={data["titleZone"] || ""}
            />
            <Input
              label="Seção "
              id="titleSection"
              onChange={handleChange}
              value={data["titleSection"] || ""}
            />
          </fieldset>
          <fieldset>
            <legend>Contrato de Trabalho:</legend>
            <Input
              label="Data de Admissão"
              type="date"
              id="admission"
              onChange={handleChange}
              value={data["admission"] || ""}
            />
            <Input
              label="Valor (M/H)"
              id="value"
              onChange={handleChange}
              value={data["value"] || ""}
            />
            <Input
              label="Função"
              id="function"
              onChange={handleChange}
              value={data["function"] || ""}
            />
            <Input
              label="CBO"
              id="cbo"
              onChange={handleChange}
              value={data["cbo"] || ""}
            />
            <div id="experiencia">
              Contrato de Experiência:
              <div>
                <input
                  type="radio"
                  id="yes"
                  name="experiencia"
                  value={"Sim"}
                  checked
                />
                <label htmlFor="yes">sim</label>
              </div>
              <div>
                <input type="radio" id="no" name="experiencia" value={"Não"} />
                <label htmlFor="no">Não</label>
              </div>
              <Input
                label="Dias"
                type="number"
                id="esperienceDays"
                onChange={handleChange}
                value={data["esperienceDays"] || ""}
              />
            </div>
            <div id="ctpd">
              <div>
                <input type="radio" id="yes" name="CTPD" checked />
                <label htmlFor="yes">CTPD DIGITAL</label>
              </div>
              <div>
                <input type="radio" id="no" name="CTPD" />
                <label htmlFor="no">CTPD FÍSICA</label>
                <span>(Se possue os dados à baixo)</span>
              </div>
              <div className="datas">
                <Input
                  label="Cart. Profissional"
                  id="nCarteira"
                  onChange={handleChange}
                  value={data["nCarteira"] || ""}
                />
                <Input
                  label="Série"
                  id="carteiraserie"
                  onChange={handleChange}
                  value={data["carteiraserie"] || ""}
                />
                <Input
                  label="Emissão"
                  type="date"
                  id="carteiradate"
                  onChange={handleChange}
                  value={data["carteiradate"] || ""}
                />
              </div>
            </div>
            <div id="transport">
              Vale transporte
              <div>
                <input type="radio" id="transporteYes" name="valeTransporte" />
                <label htmlFor="transporteYes">sim</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="transporteNo"
                  name="valeTransporte"
                  checked
                />
                <label htmlFor="transporteNo">Não</label>
              </div>
            </div>
          </fieldset>
          <fieldset id="horas">
            <legend>Horas:</legend>
            <div className="day">
              Segunda à Sexta: Das: <input type="time" value="08:00" /> às:
              <input type="time" value="14:20" />
              ,Das: <input type="time" value="14:50" /> às:
              <input type="time" value="15:50" />
            </div>
            <div className="day">
              Sábados: Das: <input type="time" value="08:00" /> às:
              <input type="time" value="14:20" />
              ,Das: <input type="time" value="14:50" /> às:
              <input type="time" value="15:50" />
            </div>
            <div className="day">Domingo: Folga</div>
          </fieldset>
        </div>
        <div className="page">
          <fieldset>
            <legend>Dependentes:</legend>
            <Dependents />
          </fieldset>
        </div>
      </main>
    </>
  );
};

export default AdmissionSheatPage;
