import Header from "../components/Header/Header";

const ProfilePage = () => {
  return (
    <>
      <Header />
      <main>
        <div>ProfilePage</div>
      </main>
    </>
  );
};
export default ProfilePage;
