import React from 'react'

const MonthSelector =   ({ setDate = () => {}, currentDate }) => {
    const monthList = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
  
    const addMonth = () => {
      setDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };
    const removeMonth = () => {
      setDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };
    return (
      <section className="dateSelector">
        <span onClick={removeMonth}>
          <b>{"<"}</b>
        </span>
        <span>{monthList[currentDate.getMonth()]}</span>
        <span onClick={addMonth}>
          <b>{">"}</b>
        </span>
      </section>
    );
  };

export default MonthSelector