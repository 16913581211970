import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input/Input";
import { GetApi, PostApi } from "../services/Api";
let LoginPage = () => {
  let [user, setUser] = React.useState({ email: "", password: "" });
  let [message, setMessage] = React.useState(false);
  let navigate = useNavigate();
  async function handleSubmit(e) {
    e.preventDefault();
    setMessage(false);

    let { status, message, data } = await PostApi("/users/login", user);
    if (status === 200) {
      navigate("/companies");
    } else if (status === 500) {
      setMessage(message);
    } else {
      setMessage(data.message);
    }
  }
  function handleChange({ target }) {
    let { id, value } = target;
    setUser({ ...user, [id]: value });
  }
  const verifyToken = async () => {
    let { status } = await GetApi("/users/verify");
    if (status === 200) {
      navigate("/companies");
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);
  return (
    <main id="login" onSubmit={handleSubmit}>
      <header>
        <h1>Login</h1>
      </header>
      <div>{message}</div>
      <form>
        <Input
          id="email"
          type="email"
          name="email"
          label="email"
          value={user.email}
          onChange={handleChange}
          required
        />

        <Input
          id="password"
          required
          type="password"
          name="password"
          label="Senha"
          value={user.password}
          onChange={handleChange}
          minLength="8"
        />

        <button type="submit">Login</button>
      </form>
    </main>
  );
};

export default LoginPage;
