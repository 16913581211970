import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PostApi } from "../services/Api";
const RegisterPage = () => {
  let [user, setUser] = React.useState({
    username: "",
    email: "",
    password: "",
  });
  let [message, setMessage] = React.useState(false);
  let navigate = useNavigate();
  async function handleSubmit(e) {
    e.preventDefault();
    setMessage(false);
    let { body } = await PostApi("/users/register", user);

    if (body.created) {
      navigate("/companies");
      setMessage("Registrado");
    } else {
      setMessage("Email já cadastrado");
    }
  }
  function handleChange({ target }) {
    let { id, value } = target;
    setUser({ ...user, [id]: value });
  }
  return (
    <main id="register" onSubmit={handleSubmit}>
      <header>
        <h1>Register</h1>
        Registre-se
      </header>
      <h3>{message}</h3>
      <form>
        <div>
          <label htmlFor="username">Nome:</label>
          <br />
          <input
            required
            type="text"
            name="username"
            id="username"
            onChange={handleChange}
            value={user.username}
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <br />
          <input
            id="email"
            required
            type="email"
            name="email"
            value={user.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="password">Senha:</label>
          <br />
          <input
            id="password"
            required
            type="password"
            name="password"
            value={user.password}
            onChange={handleChange}
          />
        </div>
        <div>
          <button type="submit">Registrar</button>
          <Link to="/login">Login</Link>
        </div>
      </form>
    </main>
  );
};

export default RegisterPage;
