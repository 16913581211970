import React from "react";
import "./styles.css";
const Input = ({
  onChange = () => {},
  onClick = () => {},
  onFocus = () => {},
  type = "Text",
  label = "label",
  id = "id",
  name,
  value = "",
  minLength = 0,
  min = 0,
  children,
  ...outros
}) => {
  return (
    <>
      <section className="InputComponent">
        <input
          className={value !== "" || type === "date" ? "active" : "clear"}
          id={id}
          type={type}
          onChange={onChange}
          value={value}
          {...outros}
        />
        <label htmlFor={id}>{label}</label>
      </section>
    </>
  );
};

export default Input;
