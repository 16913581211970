import React from "react";
import Mask from "../../../../services/Mask";

let statusTypes = ["Ativo", "Demitido", "Auxilio Doença", "Frelancer"];
const View = ({ worker }) => {
  const getBirthDate=(date)=>{
    let currentDate=new Date(date)
    currentDate.setDate(currentDate.getDate()+1)
    return currentDate
  }
  return (
    <section>
      <h1>{worker.name}</h1>
      <div>
        <b>CPF:</b> {Mask.cpf(worker.cpf)}
      </div>
      <div>
        <b>Nascimento:</b> {Mask.dataPop(getBirthDate(worker.birthday))}
      </div>
      <div>
        <b>Setor | função:</b> {worker.sector} | {worker.task}
      </div>
      <div>
        <b>Status:</b> {statusTypes[worker.status]}
      </div>
    </section>
  );
};

export default View;
