import React, { useEffect, useState } from 'react'
import Mask from '../../../../../../services/Mask';


const ConsumesResume = ({ consumes = [] }) => {
    let amountReduce = (current, next) => current + next.amount;
    let ValueReduce = (current, next) => current + next.amount * next.value;
  
    if (!consumes.length) {
      return null;
    }
    return (
      <section id="Resume">
        <span>{""}</span>
        <span>{""}</span>
        <span>{consumes.reduce(amountReduce, 0)}</span>
        <span>{Mask.moeda(consumes.reduce(ValueReduce, 0))}</span>
        <span>{""}</span>
      </section>
    );
  };
  

  

export default ConsumesResume