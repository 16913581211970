import React, { useState } from "react";
import Input from "../../../../components/Input/Input";
import { useParams } from "react-router-dom";
import Mask from "../../../../services/Mask";
import { PutApi } from "../../../../services/Api";

const Update = ({ worker, updateWorkers }) => {
  let { companie_id, worker_id } = useParams();
  let [updatedWorker, setUpdatedWorker] = useState({ ...worker });

  let handleChange = ({ target }) => {
    setUpdatedWorker({ ...updatedWorker, [target.id]: target.value });
  };
  let handleSubmit = async (event) => {
    event.preventDefault();
    let { status, ...rest } = await PutApi(
      `/workers/${worker_id}`,
      updatedWorker
    );
    if (status === 200) {
      updateWorkers();
    }
    console.log(status, rest);
  };

  return (
    <section className="">
      <h1>Atualizar</h1>
      <form onSubmit={handleSubmit}>
        <Input
          id="name"
          label="Nome"
          onChange={handleChange}
          value={updatedWorker.name}
        />
        <Input
          id="cpf"
          label="CPF"
          onChange={handleChange}
          value={Mask.cpf(updatedWorker.cpf)}
          maxLength="14"
        />
        <Input
          id="birthday"
          label="Nascimento"
          type="date"
          onChange={handleChange}
          value={updatedWorker.birthday}
        />
        <Input
          id="phone"
          label="Telefone"
          onChange={handleChange}
          value={Mask.phone(updatedWorker.phone)}
          maxLength="17"
        />
        <Input
          id="address"
          label="Endereço"
          onChange={handleChange}
          value={updatedWorker.address}
        />

        <Input
          id="sector"
          label="Setor"
          onChange={handleChange}
          value={updatedWorker.sector}
        />
        <Input
          id="task"
          label="Função"
          onChange={handleChange}
          value={updatedWorker.task}
        />
        <select
          name="status"
          id="status"
          onChange={handleChange}
          value={updatedWorker.status}
        >
          <option value={0}>Ativo</option>
          <option value={1}>Demitido</option>
          <option value={2}>Auxilio doença</option>
          <option value={3}>Freelancer</option>
        </select>
        <button type="submit">Editar</button>
      </form>
    </section>
  );
};

export default Update;
