import React, { useEffect, useState } from "react";
import Consumes from "./components/Consumes/Consumes";
import Properties from "../../../components/Properties/Properties";
import { useNavigate, useParams } from "react-router-dom";
import Mask from "../../../services/Mask";
import View from "./components/View";
import Update from "./components/Update";
import { DestroyApi, GetApi } from "../../../services/Api";

const WorkerPage = ({ worker = {}, update = () => {} }) => {
  let { worker_id, companie_id } = useParams();
  let [deleteComfirmation, setDeleteConirmation] = useState(false);
  let [updateOpen, setUpdateOpen] = useState(false);
  let Navigate = useNavigate();
  let [properties, setProperties] = useState([{ key: "Loading" }]);

  const getProperties = async () => {
    let { status, body } = await GetApi(`workers/${worker.id}/properties`);

    if (status === 200) {
      setProperties(body.properties);
    }
  };

  let handleDelete = async () => {
    if (!deleteComfirmation) {
      return setDeleteConirmation(true);
    }

    let { status } = await DestroyApi(
      `companies/${companie_id}/workers/${worker_id}`
    );
    if (status === 500) {
      await update();
      Navigate(`/companie/${companie_id}/workers`);
    }
  };
  let handleUpdate = () => {
    setUpdateOpen(!updateOpen);
  };
  useEffect(() => {
    getProperties();
  }, []);
  return (
    <>
      <section>
        {updateOpen ? (
          <Update
            worker={worker}
            updateWorkers={async () => {
              await update();
              setUpdateOpen(false);
            }}
          />
        ) : (
          <View worker={worker} />
        )}
        <button onClick={handleDelete}>
          {!deleteComfirmation ? "Deletar" : "Confirmar"}
        </button>
        <button onClick={handleUpdate}>
          {!updateOpen ? "Atualizar" : "Cancelar"}
        </button>
        <Properties
          workerId={worker.id}
          properties={properties}
          setProperties={setProperties}
        />
        <Consumes hidden={updateOpen} />
      </section>
    </>
  );
};

export default WorkerPage;
