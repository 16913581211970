import React, { useState } from "react";
import Input from "../../../components/Input/Input";
import Mask from "../../../services/Mask";

const Dependents = () => {
  let [dependents, setDependents] = useState([]);
  const removeDependent = (id) => {
    setDependents(dependents.filter((dependent) => dependent.id !== id));
  };
  const addDependent = (dependent) => {
    setDependents([...dependents, { id: dependents.length, ...dependent }]);
  };
  if (!dependents.length)
    return (
      <>
        <h1>Sem Dependentes</h1>
        <Create add={addDependent} />
      </>
    );

  return (
    <div id="dependentes">
      {dependents.map((dependent) => (
        <Dependent
          key={dependent.id}
          dependet={dependent}
          remove={removeDependent}
        />
      ))}
      <Create add={addDependent} />
    </div>
  );
};

export default Dependents;

const Dependent = ({ dependet, remove }) => {
  let { name, cpf, id } = dependet;
  return (
    <div className="dependent">
      <p>Nome: {name}</p>
      <p>CPF: {Mask.cpf(cpf)}</p>
      <button onClick={() => remove(id)}>remover</button>
    </div>
  );
};

const Create = ({ add }) => {
  let [dependent, setDependent] = useState({});
  const handleChange = ({ target }) => {
    let { value, id } = target;

    setDependent({ ...dependent, [id]: value });
  };
  const handleCreate = (e) => {
    e.preventDefault();
    add(dependent);
    setDependent({});
  };
  return (
    <form onSubmit={handleCreate} id="createDependents">
      <Input
        label="Nome"
        id="name"
        onChange={handleChange}
        value={dependent["name"]}
        required
      />
      <Input
        label="CPF"
        id="cpf"
        onChange={handleChange}
        minLength={14}
        maxLength={14}
        value={Mask.cpf(dependent["cpf"])}
        required
      />
      <input type="submit" value="Criar" />
    </form>
  );
};
