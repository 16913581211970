import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./styles.css";
import { PostApi } from "../../services/Api";
const Properties = ({
  properties = [],
  workerId,
  update=()=>{},
  setProperties=()=>{}
}) => {
  let [loading, setLoading] = useState(false);
  let [key, setKey] = useState("");
  let [value, setValue] = useState("");
  let keyRef = useRef();
  let handleSubmit =async (element) => {
    element.preventDefault();
    if(loading)return
    setLoading(true)
    let{status,body}=await PostApi(`workers/${workerId}/properties`,{key,value})
    setLoading(false)
    if(status===201){
      setProperties([...properties,body.propertie])
      setKey('')
      setValue('')
    }
    
  };

  if (properties.length === 0) {
    properties = [{ key: "Sem Propriedades", value: "" }];
  }

  return (
    <section id="Properties">
      <h2>{"Propriedades:"}</h2>
      <article id="Properties">
        {properties.map(({ key, value }, index) => {
          return (
            <section key={index}>
              <div>{key}</div>
              <div>{value}</div>
            </section>
          );
        })}
        
          <form onSubmit={handleSubmit}>
            <section className="create">
              <div>
                <input
                  ref={keyRef}
                  type="text"
                  placeholder="Chave"
                  value={key}
                  onChange={({ target }) => {
                    if (properties.find(e=>e.value===target.value)) {
                      target.setCustomValidity("Essa propriedade já existe");
                    } else {
                      target.setCustomValidity("");
                    }
                    setKey(target.value);
                  }}
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="valor"
                  value={value}
                  onChange={({ target }) => setValue(target.value)}
                  required
                />
              </div>
              <div>
                <input type="submit" value="criar" />
              </div>
            </section>
          </form>
        
      </article>
    </section>
  );
};

export default Properties;
