//const baseUrl = "http://vbugs-server:8080/api";
// const baseUrl = "http://192.168.3.108:8080/api";
//const baseUrl = "https://8080-vbugs130-tuaterra-3ts3kw02gt3.ws-us106.gitpod.io/api";
// const baseUrl = "https://tuaterra.sistapp.com/api";
const baseUrl = `${window.location.origin}/api`;

let thenFunction = async (response) => {
  let { status, ok, url } = response;
  let body = await response.json();
  return { status, ok, url, body };
};
let catchFunction = (error) => ({
  status: 500,
  message: "Erro ao entrar em contato com o servidor",
  raw: error,
});
const queryParams = (params) => {
  if (!params) return "";
  return `?${new URLSearchParams(params)}`;
};

export const GetApi = async (url = "/", params) => {
  if (!url.startsWith("/")) url = "/" + url;
  return fetch(baseUrl + url + queryParams(params), {
    method: "GET",
    credentials: "include",
  })
    .then(thenFunction)
    .catch(catchFunction);
};
export const PostApi = (url = "/", body = {}, params) => {
  if (!url.startsWith("/")) url = "/" + url;
  return fetch(baseUrl + url + queryParams(params), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(thenFunction)
    .catch(catchFunction);
};
export const DestroyApi = async (url = "/", params) => {
  if (!url.startsWith("/")) url = "/" + url;
  return await fetch(baseUrl + url + queryParams(params), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(thenFunction)
    .catch(catchFunction);
};
export const PutApi = async (url = "/", body, params) => {
  if (!url.startsWith("/")) url = "/" + url;
  return await fetch(baseUrl + url + queryParams(params), {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(thenFunction)
    .catch(catchFunction);
};
