import React from "react";
import { Link } from "react-router-dom";
const MainPage = () => {
  return (
    <main id="main">
      <Link to="/login">login</Link>
      <br />
      <Link to="/register">register</Link>
    </main>
  );
};

export default MainPage;
