import Header from "../components/Header/Header";

const DashBoardPage = () => {
  return (
    <>
      <Header />
      <main>
        <div>DashBoardPage</div>
      </main>
    </>
  );
};
export default DashBoardPage;
