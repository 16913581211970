import React from "react";
import { DestroyApi, PostApi } from "../../../services/Api";

const RemoveButton = ({ worker, name, consumes, update }) => {
  const handleClick = async () => {
    if (!consumes.length) return;
    let order = consumes
      .filter((consume) => consume.name === name)
      .sort((a, b) => a - b)
    if (!order.length) return;

    let { status, body } =await DestroyApi(
      `workers/${worker.id}/consumes/${order.pop().id}`
    );
    if(status===200) update()
  };
  return (
    <div className={['consumebutton', 'button']} onClick={handleClick}>
      -
    </div>
  );
};

export default RemoveButton;
